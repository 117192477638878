import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import TestimonialSliderStyle3 from '../Slider/TestimonialSliderStyle3';

import { Helmet } from "react-helmet-async";



export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
	
	<Helmet>
        <title>Our Services | Website Designing & Digital Marketing Experts </title>
        <meta name="description" content="Explore our wide range of services at Vishal Web Tech – from website designing and development to digital marketing and graphic designing solutions tailored for your business needs." />
		
        <meta name="keywords" content="Digital Marketing Company, Digital Marketing Services, Digital Marketing Agency, Digital Marketing Firm, Digital Marketing in Delhi, Digital Marketing Services in India, Digital Marketing Agency in Delhi, Digital Marketing Services in Delhi, Best Digital Marketing Agency in Delhi" />
		
        <meta property="og:title" content="Contact Us | Get In Touch With Vishal Web Tech Today" />
		
        <meta property="og:description" content="Explore our wide range of services at Vishal Web Tech – from website designing and development to digital marketing and graphic designing solutions tailored for your business needs." />
		
      </Helmet>

      <PageHeading 
        title='Our Services'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Services'
      />
      
      {/* Start Servide Section */}
      <div className="cs-gradient_1">
        <Spacing lg="150" md="80" />
        <Div className="cs-shape_wrap_4">
          <Div className="cs-shape_4"></Div>
          <Div className="cs-shape_4"></Div>
          <Div className="container">
            <Div className="row">
              <Div className="col-xl-4">
                <SectionHeading
                  title="Empowering Businesses With Our Services"
                  subtitle=""
                />
                <Spacing lg="90" md="45" />
              </Div>
              <Div className="col-xl-8">
                <Div className="row">
                  <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="Digital Marketing Services"
                      link="/services/digital-marketing-services"
                      src="/images/digital-marketing-services.jpg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="Website Designing & Development"
                      link="/services/website-designing-development"
                      src="/images/website-development.jpg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="Software Development"
                      link="/services/software-development-company"
                      src="/images/software-development.jpg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="Technology"
                      link="/services/graphic-designing"
                      src="/images/graphic designing.jpg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  
                  
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="150" md="80" />
      </div>
      {/* End Servide Section */}
      
      
      {/* Start Testimonial Section */}
      <section>
        <div className="cs-height_145 cs-height_lg_75" />
        <div className="container">
          <SectionHeading
            title="Customer say something ( Google Reviews)"
            subtitle="Clients Feedback"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
          <TestimonialSliderStyle3 />
        </div>
       
      </section>
      {/* End Testimonial Section */}
    </>
  )
}

import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';

import ContactForm from "../ContactForm/ContactForm";

import { Helmet } from "react-helmet-async";


export default function ContactPage() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (

    <>
	
	<Helmet>
        <title>Contact Us | Get In Touch With Vishal Web Tech Today</title>
        <meta name="description" content="Have questions or need assistance? Contact Vishal Web Tech today for professional web design and digital marketing services tailored for your business success!" />
		
        <meta name="keywords" content="Digital Marketing Company, Digital Marketing Services, Digital Marketing Agency, Digital Marketing Firm, Digital Marketing in Delhi, Digital Marketing Services in India, Digital Marketing Agency in Delhi, Digital Marketing Services in Delhi, Best Digital Marketing Agency in Delhi" />
		
        <meta property="og:title" content="Contact Us | Get In Touch With Vishal Web Tech Today" />
		
        <meta property="og:description" content="Have questions or need assistance? Contact Vishal Web Tech today for professional web design and digital marketing services tailored for your business success!" />
		
      </Helmet>
     
	
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <ContactForm />
          </Div>
        </Div>
      </Div>
      
      <Spacing lg="50" md="40" />
    </>
  );
}

import React, { useState } from "react";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        services: '',
        message: ''
    });
    const [statusMessage, setStatusMessage] = useState('');

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
		
		 // Construct the WhatsApp message
    const whatsappMessage = `Hello! Here are my contact details:%0A
      Name: ${formData.name}%0A
      Mobile Number: ${formData.mobile}%0A
      Email: ${formData.email}%0A
      Services: ${formData.services}%0A
      Message: ${formData.message}`;
    const whatsappURL = `https://wa.me/+918285172030?text=${whatsappMessage}`;
    window.open(whatsappURL, '_blank'); // Opens WhatsApp

        // Send form data to the PHP script
        fetch('https://vishalwebtech.in/sendEmail.php', {  // Replace with your actual PHP URL
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(formData).toString()
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setStatusMessage("Your message has been sent successfully!");
                setFormData({
                    name: '',
                    mobile: '',
                    email: '',
                    services: '',
                    message: ''
                });
            } else {
                setStatusMessage(data.message);
            }
        })
        .catch((error) => {
            setStatusMessage('Failed to send message. Please try again.');
        });
    };

    return (
        <div>
            <h2>Get in touch with Professional Team</h2>
			<h3> Call Us: <a href="tel:+918285172030">+91-8285172030</a></h3>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="mobile">Mobile Number</label>
                    <input
                        type="tel"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="services">Services</label>
                    <select
                        name="services"
                        value={formData.services}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select a Service</option>
                        <option value="Web Development">Web Development</option>
                        <option value="Digital Marketing">Digital Marketing</option>
                        <option value="Software Development">Software Development</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="message">Message</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Send Message</button>
            </form>
            {statusMessage && <p>{statusMessage}</p>}
        </div>
    );
};

export default ContactForm;

import AboutPage from './components/Pages/AboutPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';	
import Digital from './components/Pages/Digital';
import DigitalMarketingServices from './components/Pages/DigitalMarketingServices';
import WebsiteDesigningDevelopment from './components/Pages/WebsiteDesigningDevelopment';
import SoftwareDevelopment from './components/Pages/SoftwareDevelopment';
import GraphicDesigning from './components/Pages/GraphicDesigning';
import OurServices from './components/Pages/OurServices';


import Layout from './components/Layout';
import FaqPage from './components/Pages/FaqPage';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import "./scss/common/CallUsPopup.scss"; // Import SCSS file
import './scss/common/_whatsapp.scss'; // Add CSS file for styling
import './scss/common/ContactForm.scss'; // Add CSS file for styling

import DynamicPage from './components/DynamicPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import DigitalAgency from './components/Pages/DigitalAgency';

import { HelmetProvider } from "react-helmet-async";

import React, { useEffect } from "react";
import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-2BGTPLXWMW"; // Replace with your GA4 Measurement ID

function App() {
	
	
	
	useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID);
    ReactGA.send("pageview"); // Track initial page load
  }, []);

  return (
  
  <HelmetProvider>
  
    <div className="App">
      <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Digital />} />

          <Route path="about" element={<AboutPage />} />
          <Route path="our-services" element={<OurServices />} />
          <Route
            path="services/digital-marketing-services"
            element={<DigitalMarketingServices />}
          />
		  <Route
            path="services/website-designing-development"
            element={<WebsiteDesigningDevelopment />}
          />
		  <Route
            path="services/software-development-company"
            element={<SoftwareDevelopment />}
          />
		  <Route
            path="services/graphic-designing"
            element={<GraphicDesigning />}
          />
          
          
          <Route path="contact" element={<ContactPage />} />

          {/* Define a route with a dynamic URL parameter */}
          <Route path="/website-designing-company/:location" element={<DynamicPage />} />
		  
		  <Route path="/:location" element={<DigitalAgency />} />
        
          
          <Route path="faq" element={<FaqPage />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          
          
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
	  

	  
    </>
	
      <a
        href="https://wa.me/+918285172030"  // Replace with your WhatsApp number
        className="whatsapp-float"
        target="_blank"
        rel="noopener noreferrer"
      >
         <FontAwesomeIcon icon={faWhatsapp} size="1x" />  {/* WhatsApp Icon */}
		 
      </a>
	  
	  <div>
      {/* Other content */}
    </div>
	  
	  
    </div>
	
</HelmetProvider>
	
  );
}

export default App;



